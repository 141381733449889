import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../../components/layout";
import Trainers from "../../components/Trainers";
import InstagramFeed from "../../components/InstagramFeed";
import HeroHeader from "../../components/HeroHeader";
import Seo from "../../components/Seo";
import Jobs from "../../components/Jobs";
import FreeTrialModal from "../../components/Modal/FreeTrialModal";

const ourTrainingPhilosophyId = "training-philosophy";
const ourTrainersId = "our-trainers";
const becomeATrainerId = "become-a-trainer";

const heroHeaderQuery = graphql`
  query {
    image: file(relativePath: { eq: "about-us-banner.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;

const AboutUsPage = () => {
  const headerImage = useStaticQuery(heroHeaderQuery);
  return (
    <Layout>
      <Seo
        title="About Us"
        meta_title="About Us | FEAT Fitness"
        meta_desc="Read more about FEAT Fitness and outdoor group fitness training. FEAT Fitness brings authentic human connection to peoples world's through fun community outdoor training."
        slug="about-us"
      />
      <main>
        <div>
          {/* Hero card */}
          <HeroHeader
            title="About us at FEAT Fitness"
            background_image={headerImage?.image}
            options={[
              {
                title: "Our Training Philosophy",
                to: `#${ourTrainingPhilosophyId}`,
              },
              { title: "Our Trainers", to: `#${ourTrainersId}` },
              { title: "Become a Trainer", to: `#${becomeATrainerId}` },
            ]}
          />
          {/* End hero */}

          {/* Training with FEAT Fitness */}
          <div
            className="py-12 md:py-16 px-6 sm:px-16 md:px-32 max-w-screen-lg m-auto text-feat-darkgray"
            id={ourTrainingPhilosophyId}
          >
            <h2 className="text-heading uppercase text-2xl sm:text-4xl text-feat-darkgray text-center mb-4">
              Our Training Philosophy
            </h2>
            <div className="text-base font-light text-feat-darkgray text-center mb-8">
              FEAT Fitness started in 2018 when Barton and his co-founders
              wanted to expand their ability to bring human connection back into
              people's worlds. It was agreed the best way to do this was through{" "}
              <span className="text-feat-blue font-semibold">
                fun community outdoor training
              </span>
              . This is how FEAT Fitness was born.
            </div>
            <div className="text-base font-light text-feat-darkgray text-center mb-8">
              We wanted to get people out of the sterile gym environment where
              the air is recycled &amp; temperature controlled and it's
              encouraged to disconnect from others by putting those headphones
              on. FEAT is all about the outdoors, and those around you, we want
              to share the truly rewarding experience of being outdoors in the
              fresh air and being connected with those training with you.
            </div>
            <h3 className="text-heading uppercase text-xl sm:text-2xl mb-2 text-feat-darkgray text-center">
              The FEAT Philosophy
            </h3>
            <div className="text-bas font-light text-feat-darkgray text-center mb-8">
              <p className="mb-4">
                At FEAT Fitness we know the human body is made for movement and
                is capable of amazing physical feats. By demanding more from
                your body and pushing through pain barriers, you gain a sense of
                clarity and growth that no promise of comfort could ever
                deliver.
              </p>
              <p>
                So when you come out the other side, when you succeed and make
                it through this temporary state of existence, you are a more
                complete version of yourself. By taking this on, you are also
                empowering others to understand that they too can challenge
                themselves and grow to new heights.
              </p>
            </div>
            <h3 className="text-heading uppercase text-xl sm:text-2xl mb-2 text-feat-darkgray text-center">
              FEAT's Core Values
            </h3>
            <div className="text-bas font-light text-feat-darkgray text-center mb-8">
              <p className="mb-4">
                Our aim at FEAT is to create an environment for people to play
                and ultimately have more{" "}
                <span className="font-bold text-feat-green">FUN</span>. We
                believe that fun through play is your gateway to full self
                expression and a true, fulfilling way of being.
              </p>
              <p className="mb-4">
                We believe that life is about climbing your mountain as you
                empower those around you to take the challenge and climb theirs.
                The best way to empower those in your life is to have the people
                around you{" "}
                <span className="font-bold text-feat-orange">EMPOWERING</span>{" "}
                you.
              </p>{" "}
              <p className="mb-4">
                Support through{" "}
                <span className="font-bold text-feat-blue">ACCOUNTABILITY</span>{" "}
                is what defines us at FEAT. Staying in action is what makes the
                invisible joys in life visible. At the heart of ACCOUNTABILITY
                is our strength to be our word. As we make and keep commitments,
                we begin to establish an inner integrity that gives us the
                courage to accept responsibility for our own lives.
              </p>{" "}
              <p className="mb-4">
                <span className="font-bold text-feat-darkgrey">TRAINING</span>{" "}
                is where the magic happens! Not only do you get a great workout,
                but at TRAINING you get to actualise humans two states of being
                – being together and being in nature. We connect to the
                community of people around us by pushing through together and
                tackling the challenges of the training session.
              </p>{" "}
              <p className="mb-4">
                <span className="font-bold text-feat-purple">COMMUNITY</span> is
                both the journey and the destination for us at FEAT. We get that
                environment is more important than will. By creating a space for
                people to come together each day is the road to success. Having
                like minded individuals lifting you up, will change the
                trajectory of your path so much faster than anything you can
                will yourself.
              </p>
            </div>
            <h3 className="text-heading uppercase text-xl sm:text-2xl mb-2 text-feat-darkgray text-center">
              What Makes FEAT Different?
            </h3>
            <div className="text-base font-light text-feat-darkgray text-center mb-8">
              <p className="mb-4">
                Following the FEAT Philosophy and Core Focus, FEAT is unique to
                other fitness solutions because we believe in and practice the
                following key areas.
              </p>
              <ul>
                <li>
                  We consistently support our members through accountability,
                </li>
                <li>We embrace the outdoors,</li>
                <li>
                  We focus on authentic human connection, and most importantly
                </li>
                <li>
                  We guarantee that{" "}
                  <span>
                    our FEAT Accountability Model will connect you to so much
                    more
                  </span>
                </li>
              </ul>
            </div>
          </div>
          {/* END Training with FEAT Fitness */}

          {/* Our Trainers */}
          <div
            className="py-12 md:py-16 px-6 sm:px-32 text-feat-darkgray bg-feat-lightgray"
            id={ourTrainersId}
          >
            <h2 className="text-heading uppercase text-3xl sm:text-4xl text-feat-darkgray text-center mb-2 sm:mb-8">
              Our Trainers
            </h2>
            <div className="pt-8 text-center">
              <Trainers />
            </div>
          </div>
          {/* END Our Trainers */}

          {/* Become a Trainer */}
          <Jobs id={becomeATrainerId} />
          {/* END Become a Trainer */}

          {/* Instagram Feed */}
          <InstagramFeed />
          {/* End Instagram Feed */}
        </div>
      </main>
    </Layout>
  );
};

export default AboutUsPage;
